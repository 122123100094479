<template>
  <div class="familyModle">
    <!-- <div class="back_view">
      <image src="https://konewx.obs.cn-south-1.myhuaweicloud.com/back_icon.png" class="back_icon" @click="toback"></image>
      <div class="bar_text">激活设备</div>
      <div style="color: #FFFFFF;">1 </div>
    </div> -->
    <!-- <div class="title">“激活设备，免费领特权”</div>
    <div class="Rights" v-for="(item,index) in list" :key="index">
      <div class="R_title">
        <div class="tit_icon">特权{{ index + 1 }}</div>
        {{ item.rights_show_name }}
      </div>
      <div class="R_info">
        <div class="R_text" v-for="(data,idx) in item.children" :key="idx">{{ data.rights_show_name }}</div>
      </div>
    </div> -->
    <div class="login_btn">
      <div class="login_btn_cont">
        <button class="btn" @click="login">授权激活</button>
        <div class="tishi">授权用户账号，绑定设备</div>
      </div>
    </div>
  </div>
</template>
<script>
import qs from 'qs'
import {Toast,Dialog } from 'vant'


export default {
  name: 'KVip-no',
  data() {
    return {
      buyflag: false,
      show: false,
      mac: '',
      start_time: '',
      end_time: '',
      list: [],
      yongHu:null,
      ceshiCan:'',
    }
  },
  created() {
    let _this = this
    // 获取mac
    this.mac = localStorage.getItem('mac')
    this.is_support_film = this.getQueryVariable('is_support_film')
    //查询当前页面的list
    _this.axios.get('/api/activate/getActivateRightsType?pattern_id=1').then(data => {
      _this.list = data.data.list
    }).catch(err => {
      console.log(err)
    })
    // 判断是否有授权参数
    console.log('进入')
    if(this.getUrlKey('code')){
      this.axios.get('/wechat/user/getUserInfo?code='+this.getUrlKey('code')).then(data => {
        _this.yongHu = data.data
         _this.axios.post('/api/activate/register', {
            mac: _this.mac || '',
            pattern_id: 1,
            openid:_this.yongHu.openid,
            unionId:_this.yongHu.unionid,
            nickName:_this.yongHu.nickname,
            avatar:_this.yongHu.headimgurl
        }).then(data => {
          if (data.return_code == 200) {
           _this.$router.push({path:'/drawSuccess',query:{
             url:data.data.url
           }})
          }else{
            if(data.return_code == 901){
                Dialog.alert({
                  title: '激活失败',
                  message: data.return_msg,
                }).then(() => {
                  // on close
                });
            }else{
              Toast(data.return_msg);
            }
           
            
          }
        }).catch(err => {
          console.log(err)
        })
      }).catch(err => {
        console.log(err)
      })
    }
  },

  methods: {
    login() {
      if(this.getUrlKey('code')){
        //   this.axios.post('/api/activate/register', {
        //   mac: this.mac || '',
        //   pattern_id: 1,
        //   openid:this.yongHu.openid,
        //   unionId:this.yongHu.unionid,
        //   nickName:this.yongHu.nickname,
        //   avatar:this.yongHu.headimgurl
        // }).then(data => {
        //   console.log(data)
        //   if (data.return_code == 200) {
        //    this.$router.push('/drawSuccess')
        //   }
        // }).catch(err => {
        //   console.log(err)
        // })



      }else{
          let a = document.createElement('a');
          let redurl = `https://wbe.basic.home-music.cn/Selection`;
          redurl = encodeURIComponent(redurl);
          let val = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx7f7fc5d15a61cea8&redirect_uri=${redurl}&response_type=code&scope=snsapi_userinfo&state=snsapi_userinfo#wechat_redirect`;
         // let val = 'http://localhost:8080?corpid=wwbf26226ba7ba2470&code=m1hbDlKVNf3hKp16bCjkoVI5m11iHH_Z-Q4SCIEbtZc&state=snsapi_userinfo'
          a.setAttribute('href', val);
          a.setAttribute('target', '_self');
          a.setAttribute('id', 'js_a');
          //防止反复添加
          if (document.getElementById('js_a')) {
            document.body.removeChild(document.getElementById('js_a'));
          }
          document.body.appendChild(a);
          a.click();
      }



      

      // this.$router.push('/drawSuccess')
    },
    getUrlKey(name) {
      return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ""])[1].replace(/\+/g, '%20')) || null
    },
    weixinClosePage() {
      if (typeof WeixinJSBridge == "undefined") {
        if (document.addEventListener) {
          document.addEventListener('WeixinJSBridgeReady', weixin_ClosePage, false);
        } else if (document.attachEvent) {
          document.attachEvent('WeixinJSBridgeReady', weixin_ClosePage);
          document.attachEvent('onWeixinJSBridgeReady', weixin_ClosePage);
        }
      } else {
        this.weixin_ClosePage();
      }
    },

    weixin_ClosePage() {
      WeixinJSBridge.call('closeWindow');
    },
    getQueryVariable(variable) {
      var query = window.location.search.substring(1);
      var vars = query.split("&");
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == variable) {
          return pair[1];
        }
      }
      return false;
    }
    ,
    close: function () {

    }


    ,
    getLocalTime(nS) {
      return new Date(parseInt(nS) * 1000).toLocaleString().replace(/:\d{1,2}$/, ' ');
    }
    ,
    confirm: function () {
      let _this = this
      _this.$toast.loading({
        message: '加载中...',
        forbidClick: true,
      });
      let scene = []
      _this.list.map(item => {
        if (item.check == true) {
          scene.push(item.id)   
        }
      })
      if (scene.length > 0) {
        this.axios.post('/api/activate/newActiveM210Mac', {
          mac: _this.mac || '00:05:29:CE:01:6E',
          use_scene: scene,
        }).then(data => {
          if (data.code == 200) {
            _this.$toast.success('成功开通VIP');
            _this.start_time = _this.getLocalTime(data.data.active_time).substring(0, 10)
            _this.end_time = _this.getLocalTime(data.data.expire_time).substring(0, 10)
            console.log(_this.start_time)
            console.log(_this.end_time)
            _this.show = false
            _this.buyflag = true
          } else {
            _this.$toast.fail(data.msg);
            _this.show = false
          }
        }).catch(err => {
          console.log(err)
          _this.$toast.fail(data.msg);
        })
      } else {
        _this.$toast.fail('请选择场景');
      }

    }
  }
}
</script>
<style scoped>
.familyModle {
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  background: url('https://konewx.obs.cn-south-1.myhuaweicloud.com/jihuo_bg.png')no-repeat;
  background-size:100% 100% ;
}

.title {
  height: 75px;
  width: 100%;
  font-weight: bold;
  color: #000000;
  text-align: center;
  margin-top: 40px;
  font-size: 24px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #222222;
  line-height: 33px;
}

.Rights {
  width: 100%;
}

.R_title {
  color: #000000;
  font-weight: bold;
  padding-left: 30px;
  font-size: 16px;
  margin-bottom: 15px;
}

.tit_icon {
  margin-top: 15px;
  width: 54px;
  height: 24px;
  background: rgba(255, 0, 251, 0.24);
  border-radius: 12px;
  background-color: rgba(255, 0, 251, 0.24);
  text-align: center;
  line-height: 24px;
  display: inline-block;
  margin-right: 15px;
  font-size: 14px;
}

.R_info {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 30px;
  box-sizing: border-box;
}

.R_text {
  width: 150px;
  text-align: center;
  height: 60px;
  line-height: 60px;
  margin-bottom: 10px;
  background: #fff3ff;
  border-radius: 10px;
  color: rgba(255, 0, 251, 1);
  font-size: 16px;
  font-weight: bold;
}

.R_text_three {
  width: 150px;
  text-align: center;
  height: 60px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #fff3ff;
  border-radius: 10px;
  color: rgba(255, 0, 251, 1);
  font-size: 16px;
  font-weight: bold;
}

.R_text_name {
  font-size: 16px;
}

.R_text_price {
  color: rgba(34, 34, 34, 0.36);
  font-size: 12px;
}

.login_btn {
  position: fixed;
  bottom: 74px;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
 
}
.login_btn_cont{
  text-align: center;
}
.tishi{
font-size: 14px;
font-weight: 400;
color: #FFFFFF;
margin-top: 10px;

}

.btn {
  text-align: center;
  line-height: 49px;
  width: 670px;
  color: rgba(255, 255, 255, 1);
  font-size: 17px;
  border: none;
  width: 315px;
  height: 49px;
  background: linear-gradient(90deg, #ff4bf0 0%, #9449ff 100%);
  border-radius: 25px;
}
</style>